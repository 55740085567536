import { CasRequest } from '@/utils/request'

/**
 * 发送短信
 */
export const sendMobile = (mobile, smsType) => {
  return CasRequest({
    url: `/sms/sms-send/${mobile}`,
    method: 'post',
    params: {
      smsType,
      autoRegister: true
    }
  })
}
